<template>
  <div class="rentSuccess">
    <div class="message">
      <img src="./img/successIcon.png" />
      <div class="txt">您的申请已经提交成功～</div>
    </div>
    <div class="top-img"><img src="./img/successBg.png" alt="" /></div>
    <div class="btn" @click="toIndex">返回首页</div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  name: "rentSuccess",
  data() {
    return {};
  },
  components: {},
  destroyed() {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    toIndex() {
      wx.miniProgram.navigateTo({
        url: `/pages/index/index`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.rentSuccess {
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  padding-top: 42px;
  .message {
    margin-top: 120px;
    margin-bottom: 130px;
    font-size: 36px;
    font-weight: 600;
    color: #5e5aff;
    justify-content: center;
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 14px;
    }
  }
  .top-img {
    width: 568px;
    height: 322px;
    margin: 0 auto;
    margin-bottom: 140px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .text {
    font-size: 32px;
    text-align: center;
    font-weight: 400;
    color: #666666;
    line-height: 44px;
    margin: 52px auto 156px;
  }
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #9591ff 0%, #5f5bff 100%);
    border-radius: 10px;
    border: 2px solid #007eff;
    margin: 0 auto;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    line-height: 66px;
    text-align: center;
  }
}
</style>
<style lang="less">
.rentSuccess {
}
</style>
